import React from 'react'
import purchase from '../assets/images/purchase.webp';
import lumyxpoLogo from '../assets/images/lumyxpo-logo.webp';
import lamp from '..//assets/images/lamp.webp';
import arrow from '../assets/images/arrow.webp';
import wave from '../assets/images/wave.webp';

function ComingSoon(){

    return(
        <section className='lumyxpo-section bg-main overflow-hidden'>
            <div className='position-relative vh-100 w-100'>
                <img className='lumyxpo-wave position-absolute w-100 h-100' src={wave} alt="Wave img" />
                <div className='h-100 w-100 position-relative'>
                    <img className='lumyxpo-logo' src={lumyxpoLogo} alt="Lumyxpo logo" />
                    <div className="coming-soon row w-100 h-100 m-0">
                        <div className="col-sm-6 h-100 p-0">
                            <img className='arrow-rotate d-none d-md-block position-relative' src={arrow} alt="Arrow to coming soon" />
                            <h1 className='lumyxpo-title'>We Are <br /> Coming Soon</h1>
                            <div className='d-flex'>
                                <img className='d-none d-md-block' src={arrow} alt="Arrow to coming soon" />
                                <img className='ms-5 d-none d-xl-block' src={purchase} alt="" />
                            </div>
                        </div>
                        <div className="col-sm-6 h-100 p-0">
                            <img className='lumyxpo-lamp' src={lamp} alt="Lumyxpo lamp" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default ComingSoon;