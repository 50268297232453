import React from 'react';
import ComingSoon from './pages/ComingSoon';
import './assets/style/style.css';

function App() {
  return (
    <ComingSoon/>
  );
}

export default App;
